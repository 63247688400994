import { defineStore } from "pinia";
import { Inertia } from "@inertiajs/inertia";
import axios from "axios";
import { useCartStore } from "./cartStore"; // Импортируем CartStore
import { useNotificationStore } from "./notificationStore";

export const useOrderStore = defineStore("order", {
    state: () => ({
        name: "",
        phone: "",
        restaurant: "",
        address: {
            street: "",
            number_house: "",
            entrance: "",
            apartment: "",
            floor: "",
            intercom_code: "",
        },
        paymentMethod: "card",
        notes: "",
        deliveryMethod: "delivery", // По умолчанию "Доставка"
        totalCost: 0, // Добавляем totalCost в state
        isLoading: false,
    }),
    actions: {
        setName(name) {
            this.name = name;
        },
        setPhone(phone) {
            this.phone = phone;
        },
        setRestaurant(restaurant) {
            this.restaurant = restaurant;
        },
        setAddress(address) {
            this.address = { ...this.address, ...address };
        },
        setPaymentMethod(paymentMethod) {
            this.paymentMethod = paymentMethod;
        },
        setNotes(notes) {
            this.notes = notes;
        },
        setDeliveryMethod(method) {
            this.deliveryMethod = method;
        },
        setTotalCost(cost) {
            this.totalCost = cost; // Устанавливаем totalCost
        },
        async submitOrder() {
            const cartStore = useCartStore(); // Получаем доступ к CartStore
            const notificationStore = useNotificationStore();
            this.isLoading = true;
            let orderData = {
                name: this.name,
                phone: this.phone,
                paymentMethod: this.paymentMethod,
                notes: this.notes,
                deliveryMethod: this.deliveryMethod,
                items: cartStore.items, // Добавляем товары из корзины
                packaging: cartStore.allPackagingDetails, // Добавляем детали упаковки
                totalCost: this.totalCost, // Используем сохраненную общую сумму заказа
            };

            // Получаем информацию о выбранном ресторане из localStorage
            const selectedRestaurant =
                localStorage.getItem("selectedRestaurant");

            if (this.deliveryMethod === "delivery") {
                // Если выбрана доставка, отправляем адрес
                orderData.address = this.address;
                // Если выбран ресторан, добавляем его в orderData
                if (selectedRestaurant) {
                    try {
                        const restaurant = JSON.parse(selectedRestaurant); // Парсим данные
                        if (restaurant && restaurant.address) {
                            console.log("restaurant111111", restaurant)
                            orderData.restaurant = restaurant.address; // Используйте поле name или другое строковое значение
                        } else {
                            console.error("Ошибка: Невозможно найти поле 'name' у ресторана.");
                        }
                    } catch (error) {
                        console.error("Ошибка парсинга данных о ресторане из localStorage:", error);
                    }
                }
            } else if (this.deliveryMethod === "pickup") {
                // Если выбран самовывоз, отправляем ресторан
                orderData.restaurant = this.restaurant;
            }

            try {
                const response = await axios.post("/orders", orderData);

                if (response.data.code === 201) {
                    // Если заказ успешно создан
                    cartStore.$reset(); // Обнуляем cartStore

                    // Показываем уведомление об успешном заказе
                    notificationStore.showNotification(
                        "Ваше замовлення успішно відправлено! Очікуйте дзвінка менеджера."
                    );

                    const restaurantData =
                        localStorage.getItem("selectedRestaurant");
                    localStorage.clear(); // Очищаем весь localStorage
                    if (restaurantData) {
                        localStorage.setItem(
                            "selectedRestaurant",
                            restaurantData
                        ); // Восстанавливаем данные о ресторане
                    }
                    Inertia.visit("/");
                    // Здесь можно сделать дополнительную логику, например, перенаправление на другую страницу
                }

                if (response.data.formHtml) {
                    // Если сервер вернул HTML формы, вставляем её в DOM и отправляем
                    const formContainer = document.createElement("div");
                    formContainer.innerHTML = response.data.formHtml;
                    document.body.appendChild(formContainer);

                    // Вручную отправляем форму
                    formContainer.querySelector("#wayforpay-form").submit();
                }
                // Здесь можно обработать успешный ответ, например, показать уведомление пользователю
            } catch (error) {
                console.error("Error submitting order:", error);
                // Здесь можно обработать ошибку, например, показать сообщение об ошибке пользователю
            } finally {
                // Завершаем загрузку после успешного ответа или ошибки
                this.isLoading = false;
            }
        },
        logState() {
            console.log("Current state:", this.$state);
        },
    },
});
