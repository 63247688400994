<template>
    <div
        v-bind="$attrs"
        class="container flex justify-between items-center w-full my-3 z-40"
    >
    <RestaurantModal />
        <div class="flex flex-row items-center gap-5 font-semibold">
            <Link href="/" role="tab" class="link link-hover text-lg"
                ><ApplicationLogo></ApplicationLogo
            ></Link>
            <Link href="/contacts" role="tab" class="link link-hover text-lg"
                >Ресторани</Link
            >
            <Link
                href="/shipping-and-payment"
                role="tab"
                class="link link-hover text-lg"
                >Доставка та оплата</Link
            >
        </div>
        <div class="flex flex-row items-center">
            <Select></Select>
        </div>        
        <div>
            <div role="tablist" class="tabs tabs-boxed font-semibold">
                <!-- <Link href="/" role="tab" class="tab text-lg">Увійти</Link> -->
            </div>
            <div><DesktopCardDrawer /></div>
        </div>
    </div>
    <TabComponent></TabComponent>
</template>

<script setup>
import ApplicationLogo from "../ApplicationLogo.vue";
import { Link } from "@inertiajs/vue3";
import Select from "../Select.vue";
import TabComponent from "../TabHeader.vue";
import { defineProps, useAttrs } from "vue";
import DesktopCardDrawer from "../DesktopCardDrawer.vue";
import RestaurantModal from "../RestaurantModal.vue";

defineProps({
    inheritAttrs: false,
});

const attrs = useAttrs();
</script>

<style lang="scss" scoped></style>
